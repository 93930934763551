<template>
	<v-container>
		<v-row>
			<v-col cols="4">
        <v-row>
          <v-col>
            <v-card>
            <v-card-text>
              <v-simple-table>
                <thead><tr><th>Роль</th></tr></thead>
                <tbody>
                <tr v-for="(role, i) in roles" :key="i" :class="isSelected(role.name)" @click="roleSelected=role.name">
                  <td>{{role.description}}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="blue" @click="dialogRole=true">Добавить роль</v-btn>
          </v-col>
          <v-col>
            <v-btn color="green" :disabled="!roleSelected" @click="savePermissions()">Сохранить настройки</v-btn>
          </v-col>
        </v-row>
			</v-col>
			<v-col>
				<v-card>
					<v-card-text v-if="roleSelected">
						<div class="text-h6">
							Унаследовать все права от роли:
						</div>
						<div v-for="(arole, i) in roles" :key="i">
							<v-checkbox
									v-if="arole.name!==roleSelected"
									v-model="permissionsSelected"
									:label="arole.description"
									:value="arole.name"
									@click="checkRolePermissions(arole.name)"
							></v-checkbox>
						</div>
						<v-divider></v-divider>
						<div class="text-h6">
							И добавить эти права:
						</div>
						<div v-for="(perm, i) in availablePermissions" :key="i">
							<v-checkbox
									v-if="!perm.disabled"
									v-model="permissionsSelected"
									:label="perm.description"
									:value="perm.name"
									:disabled="perm.disabled"
							></v-checkbox>
						</div>

						<div v-if="availablePermissions.some(x=>x.disabled===true)">
							<v-divider></v-divider>
							<div class="text-h6">Эти права наследуются от ролей:</div>
							<div v-for="(perm, i) in availablePermissions" :key="i">
								<p v-if="perm.disabled">{{perm.description}}</p>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog v-model="dialogRole" width="600">
			<v-card>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="role.name" placeholder="Название роли НА АНГЛИЙСКОМ" label="Название роли НА АНГЛИЙСКОМ" outlined></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="role.description" placeholder="Название роли НА РУССКОМ" label="Название роли НА РУССКОМ" outlined></v-text-field>
						</v-col>
						<v-col>
							<v-btn color="green" @click="saveRole()">Сохранить</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import _ from 'lodash'
	import httpClient from '../../services/http.service'
	import qs from 'querystring'
	export default {
		name: 'Index',
		data(){
			return{
				permissions: [], // вообще все права
				permissionsSelected: [], //выбранные права
				availablePermissions: [], //а тут будут доступные к выбору права
				roleSelected: false,
				roles: [],

				dialogRole: false,
				role: {}
			}
		},
		created () {
			this.getRoles()
			this.getPermissions()
		},
		watch:{
			roleSelected(v){
				this.getPermsByRole(v)
			}
		},
		methods:{
			getRoles(){
				httpClient.get('rbac/roles').then(({data})=>{
					this.roles = data
				})
			},
			saveRole(){
				httpClient.post('rbac/roles', qs.stringify(this.role)).then(({data})=>{
					if (data.success){
						this.getRoles()
						this.dialogRole = false
					}
				})
			},
			getPermissions(){
				httpClient.get('rbac/permissions').then(({data})=>{
					this.permissions = data
				})
			},
			getPermsByRole(role){
				this.availablePermissions = JSON.parse(JSON.stringify(this.permissions)) //чтобы не было магической связи между ними
				this.permissionsSelected = []
				httpClient.get('rbac/permissions?role='+role).then(({data})=>{
					//API возвращает и разрешения и роли
					_.each(data, perm=>{
						this.permissionsSelected.push(perm.child)
						if (this.roles.some(x=>x.name===perm.child)){
							//это роль. для роли надо дополнительно запросить разрешения и сделать их disabled
							this.checkRolePermissions(perm.child)
						}
					})
				})
			},
			savePermissions(){
				httpClient.post('rbac/permissions?role='+this.roleSelected, qs.stringify(this.permissionsSelected))
			},
			checkRolePermissions(role){ //при выборе роли для унаследования - сразу отметим нужные права
				let added = false
				if (this.permissionsSelected.findIndex(x=>x.name===role) >= 0){ //у нас нет event на выбор чекбокса, поэтому смотрим отдельно: чекбокс выбрали или наоборот отменили выбор
						added = true
				}
				httpClient.get('rbac/permissions?role='+role).then(({data})=>{
					_.each(data, perm=>{
						const index = this.availablePermissions.findIndex(x=>x.name===perm.child)
						if (index>-1){
							this.$set(this.availablePermissions[index], 'disabled', !added)
						}
					})
				})
			},
			isSelected(name){
				if (this.roleSelected === name){
					return 'selected'
				}
				return ''
			}
		}
	}
</script>

<style scoped>
	.selected{
		background-color: #81D4FA !important;
	}
</style>
